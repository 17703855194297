<script>
import FlagButton from '@/components/FlagButton.vue';
import {routerHelper} from "@/helpers/router-helper";

export default {
  components: {FlagButton},

  beforeCreate() {
    const id = this.$route.params.id;
    if (!id) {
      return;
    }

    routerHelper.push(`/register/${id}`)
  },

  computed: {
    getImageSize() {
      let screenWidth = screen.width;
      let width = 60;
      if (screenWidth > 576 && screenWidth <= 992) {
        width = 35;
      }

      return width;
    }
  }

};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <FlagButton :drop-left="true"></FlagButton>
      <button @click="$store.dispatch('theme/switchTheme').then(() => $store.dispatch('theme/updateTheme'))" type="button" class="btn btn-sm px-3 font-size-24 header-item">
        <i :class="$store.getters['theme/isThemeDark'] ? 'ri ri-sun-line' : 'ri ri-moon-line'" style="color: #ffffff;"></i>
      </button>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
    </div>
  </div>
    </div>
  </div>
</template>